<template>
    <div
        class="relative"
        :class="$attrs.classes"
    >
        <div
            class="relative flex items-center border rounded-custom w-full transition-colors overflow-hidden"
            :class="[styles, height]"
        >
            <div
                class="flex justify-between items-center w-full"
                :class="[ mini ? 'px-2.5' : 'px-3' ]"
            >
                <div
                    v-if="prefix"
                    class="text-xs"
                    :class="[color, prefixMargin]"
                >
                    {{ prefix }}
                </div>

                <input
                    ref="inputRef"
                    class="leading-5 w-full text-xs transition-colors bg-transparent dark:placeholder:text-gray-280"
                    :class="[
                        disabled ? 'placeholder:text-gray-100' : 'placeholder:text-gray-190 dark:placeholder:text-gray-280',
                        disabled ? disabledColor : color,
                        mini ? 'py-1.25' : 'py-3 font-medium',
                        { 'text-center': center }
                    ]"
                    v-bind="inputAttrs"
                    :readonly="disabled || readonly"
                    :placeholder="placeholder"
                    :autocomplete="autocomplete"
                    :tabindex="tabindex"
                    :type="inputType"
                    v-model="inputValue"
                    @focus="focusInput"
                    @blur="blurInput"
                    @input="onInput"
                    @keyup.enter="pressEnter"
                />

                <div
                    v-if="showCleanIcon"
                    class="flex justify-between items-center ml-3"
                >
                    <CleanIcon
                        class="text-gray-200 dark:text-gray-700 cursor-pointer"
                        @click="cleanValue"
                    />
                </div>

                <div
                    v-if="isPasswordType"
                    class="flex justify-between items-center ml-3 h-6 w-6"
                    @click="setFocusOnInput"
                >
                    <HideIcon
                        class="text-gray-200 hover:text-purple dark:hover:text-purple cursor-pointer"
                        @mouseover="hideIconOnFocus"
                        @mouseleave="hideIconOnBlur"
                    />
                </div>
            </div>

            <ProgressLine class="inset-x-0" :loading="loading"/>
        </div>

        <ErrorBlock
            :noAbsolute="errorNoAbsolute"
            :message="message"
        />
    </div>
</template>

<script setup>
import {
    ref, computed, useAttrs, watch, onMounted,
} from 'vue'

import CleanIcon from '@/components/icons/CleanIcon.vue'
import HideIcon from '@/components/icons/HideIcon.vue'
import ProgressLine from '@/components/progress/ProgressLine.vue'
import ErrorBlock from '@/components/errorBlock/ErrorBlock.vue'

const props = defineProps({
    modelValue: {
        type: [String, Number],
        default: '',
    },
    valid: {
        type: Boolean,
        default: true,
    },
    rules: {
        type: Array,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    showCleanIcon: {
        type: Boolean,
        default: false,
    },
    prefix: {
        type: String,
        default: '',
    },
    prefixMargin: {
        type: String,
        default: 'mr-1',
    },
    color: {
        type: String,
        default: 'text-gray-370 dark:text-white',
    },
    disabledColor: {
        type: String,
        default: 'text-gray-100 dark:text-gray-280',
    },
    autoFocus: {
        type: Boolean,
        default: false,
    },
    mini: {
        type: Boolean,
        default: false,
    },
    center: {
        type: Boolean,
        default: false,
    },
    inputTimeout: {
        type: Number,
        default: 0,
    },
    type: {
        type: String,
        default: 'text',
    },
    setTimeoutAutoFocus: {
        type: Number,
        default: 0,
    },
    tabindex: {
        type: String,
        default: '',
    },
    placeholder: {
        type: [Number, String],
        default: '',
    },
    autocomplete: {
        type: String,
        default: '',
    },
    maxlength: {
        type: Number,
        default: 0,
    },
    isTransparent: {
        type: Boolean,
        default: false,
    },
    customRegex: {
        type: RegExp,
        default: null,
    },
    height: {
        type: String,
        default: '',
    },
    errorNoAbsolute: {
        type: Boolean,
        default: false,
    },
})

const emits = defineEmits(['update:modelValue', 'update:valid', 'onBlur'])

const inputRef = ref(null)

const isFocus = ref(false)

const oldValue = ref(null)

const emptySearch = ref(true)

const attrs = useAttrs()

const hideIconFocused = ref(false)

const inputAttrs = computed(() => ({ ...attrs, class: {} }))

const inputValue = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emits('update:modelValue', value)
    },
})

const isPasswordType = computed(() => props.type === 'password')

const isNumberType = computed(() => props.type === 'number')

const inputType = computed(() => {
    if (isPasswordType.value) {
        return hideIconFocused.value ? 'text' : 'password'
    }

    return 'text'
})

const message = computed(() => {
    let message = null

    props.rules.forEach((r) => {
        if (!message) {
            message = typeof r(props.modelValue) !== 'boolean' ? r(props.modelValue) : null
        }
    })

    return message
})

const styles = computed(() => {
    if (props.disabled) {
        return 'pointer-events-none bg-purple-70 dark:bg-blue-770 border-gray-100 dark:border-gray-700'
    }

    const bg = props.isTransparent ? 'bg-transparent' : 'bg-purple-60 dark:bg-gray-750'

    return [
        { 'border-red-700 dark:border-red bg-red-100 dark:bg-red-900': !props.valid },
        { 'border-purple dark:border-purple bg-purple-60 dark:bg-gray-780': isFocus.value && props.valid },
        { 'border-gray-100 dark:border-blue-720 hover:border-purple dark:hover:border-purple': props.valid && !isFocus.value },
        props.valid && !isFocus.value ? bg : undefined,
    ]
})

function validationInput(value) {
    if (isNumberType.value) {
        return value.replace(/\D/g, '')
    }

    if (props.customRegex) {
        return value.replace(props.customRegex, '')
    }

    return value
}

let serverSearchInterval = null

function hideIconOnFocus() {
    hideIconFocused.value = true
}
function hideIconOnBlur() {
    hideIconFocused.value = false
}

function serverSearch(value) {
    if (!emptySearch.value || value && value.length) {
        emptySearch.value = !value

        clearInterval(serverSearchInterval)

        serverSearchInterval = setInterval(() => {
            clearInterval(serverSearchInterval)
            inputValue.value = value
        }, props.inputTimeout)
    }
}

function onInput({ target }) {
    const value = target.value

    if (props.inputTimeout) {
        serverSearch(value)
    } else if (props.maxlength) {
        inputValue.value = validationInput(value).substring(0, props.maxlength)
    } else {
        inputValue.value = validationInput(value)
    }
}

function focusInput() {
    isFocus.value = true
}

function blurInput() {
    isFocus.value = false

    setTrim()

    if (oldValue.value !== inputValue.value) {
        emits('onBlur')
        setOldValue(inputValue.value)
    }
}

function pressEnter() {
    setTrim()

    if (oldValue.value !== inputValue.value) {
        emits('onBlur')
        setOldValue(inputValue.value)
    }
}

function setTrim() {
    if (inputValue.value) {
        inputValue.value = inputValue.value.trim()
    }
}

function setOldValue(value) {
    oldValue.value = value
}

function cleanValue() {
    inputValue.value = ''
}

function setFocusOnInput() {
    setTimeout(() => {
        inputRef.value.focus()
    }, props.setTimeoutAutoFocus)
}

watch(inputValue, () => {
    emits('update:valid', !message.value)
})

watch(message, () => {
    emits('update:valid', !message.value)
}, { immediate: true })

watch(() => props.autoFocus + inputRef.value, () => {
    if (props.autoFocus && inputRef.value) {
        setFocusOnInput()
    }
}, { immediate: true })

onMounted(() => {
    setOldValue(inputValue.value)
})
</script>
