import { ROUTES } from '@/constants/routes'

import isNotAuth from '@/router/middleware/auth/isNotAuth'
import auth from '@/router/middleware/auth/auth'
import checkWorkspace from '@/router/middleware/checkWorkspace'
import accountInReview from '@/router/middleware/auth/accountInReview'
import trialEnded from '@/router/middleware/auth/trialEnded'
import isManager from '@/router/middleware/isManager'
import notBuyer from '@/router/middleware/notBuyer'
import notVendor from '@/router/middleware/notVendor'
import isOwner from '@/router/middleware/isOwner'
import isWorkspaceOwner from '@/router/middleware/isWorkspaceOwner'
import isNotReviewsTrialEnded from '@/router/middleware/auth/isNotReviewsTrialEnded'
import isNotEmptyWorkspace from '@/router/middleware/isNotEmptyWorkspace'
import isAccountDisabled from '@/router/middleware/auth/isAccountDisabled'
import notFound from '@/router/middleware/notFound'

export const BASE_ROUTES = [
    {
        path: '/auth',
        name: ROUTES.auth,
        redirect: { name: 'AuthLogin' },
        component: () => import('@/views/auth/Auth.vue'),
        children: [{
            path: 'login',
            name: ROUTES.authLogin,
            meta: {
                layout: 'empty', middleware: isNotAuth, title: 'Dialics Login', reference: ROUTES.auth,
            },
            component: () => import('@/views/auth/login/AuthLogin.vue'),
        }, {
            path: 'reset-password',
            name: ROUTES.authReset,
            meta: { layout: 'empty', middleware: isNotAuth, reference: ROUTES.auth },
            component: () => import('@/views/auth/reset-password/ResetPassword.vue'),
        }, {
            path: 'reset-password/:token',
            name: ROUTES.authSetNewPassword,
            meta: { layout: 'empty', reference: ROUTES.auth },
            component: () => import('@/views/set-new-password/SetNewPassword.vue'),
        }],
    }, {
        path: '/signup',
        name: ROUTES.register,
        meta: {
            layout: 'empty', middleware: isNotAuth, title: 'Dialics Signup', reference: ROUTES.register,
        },
        component: () => import('@/views/register/Register.vue'),
    }, {
        path: '/invite/:slug',
        name: ROUTES.inviteUser,
        meta: { layout: 'empty', reference: ROUTES.inviteUser },
        component: () => import('@/views/invite-users/InviteUsers.vue'),
    }, {
        path: '/:workspace/reporting',
        name: ROUTES.reporting,
        meta: { layout: 'main', middleware: [auth, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.reporting },
        component: () => import('@/views/call-tracking/reporting/Reporting.vue'),
    }, {
        path: '/:workspace/campaigns',
        name: ROUTES.manageCampaigns,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageCampaigns },
        component: () => import('@/views/call-tracking/campaigns/ManageCampaigns.vue'),
    }, {
        path: '/:workspace/campaigns/:slug',
        name: ROUTES.detailCampaigns,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageCampaigns },
        component: () => import('@/views/call-tracking/campaigns/DetailCampaign.vue'),
    }, {
        path: '/:workspace/tracking-numbers',
        name: ROUTES.manageNumbers,
        meta: { layout: 'main', middleware: [auth, notBuyer, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageNumbers },
        component: () => import('@/views/call-tracking/tracking-numbers/ManageTrackingNumbers.vue'),
    }, {
        path: '/:workspace/tracking-numbers/buy-tracking-numbers',
        name: ROUTES.buyNumbers,
        meta: { layout: 'main', middleware: [auth, notBuyer, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageNumbers },
        component: () => import('@/views/call-tracking/tracking-numbers/BuyTrackingNumbers.vue'),
    }, {
        path: '/:workspace/tracking-numbers/:slug',
        name: ROUTES.detailNumber,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageNumbers },
        component: () => import('@/views/call-tracking/tracking-numbers/DetailTrackingNumber.vue'),
    }, {
        path: '/:workspace/pools',
        name: ROUTES.managePools,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.managePools },
        component: () => import('@/views/call-tracking/number-pools/ManageNumberPools.vue'),
    }, {
        path: '/:workspace/pools/:slug',
        name: ROUTES.detailPool,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.managePools },
        component: () => import('@/views/call-tracking/number-pools/DetailNumberPool.vue'),
    }, {
        path: '/:workspace/blocked-numbers',
        name: ROUTES.blockNumber,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.blockNumber },
        component: () => import('@/views/call-tracking/blocked-numbers/ManageBlockedNumbers.vue'),
    }, {
        path: '/:workspace/voip-shield',
        name: ROUTES.voipShield,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.voipShield },
        component: () => import('@/views/call-tracking/voip-shield/VoipShield.vue'),
    }, {
        path: '/:workspace/voip-shield/:slug',
        name: ROUTES.detailVoipShield,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.voipShield },
        component: () => import('@/views/call-tracking/voip-shield/DetailVoipShield.vue'),
    }, {
        path: '/:workspace/destinations',
        name: ROUTES.manageDestinations,
        meta: { layout: 'main', middleware: [auth, notVendor, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageDestinations },
        component: () => import('@/views/call-tracking/destinations/ManageDestinations.vue'),
    }, {
        path: '/:workspace/destinations/:slug',
        name: ROUTES.detailDestination,
        meta: { layout: 'main', middleware: [auth, notVendor, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageDestinations },
        component: () => import('@/views/call-tracking/destinations/DetailDestination.vue'),
    }, {
        path: '/:workspace/destination-groups',
        name: ROUTES.manageDestinationGroups,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageDestinationGroups },
        component: () => import('@/views/call-tracking/destination-groups/ManageDestinationGroups.vue'),
    }, {
        path: '/:workspace/destination-groups/:slug',
        name: ROUTES.detailDestinationGroup,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageDestinationGroups },
        component: () => import('@/views/call-tracking/destination-groups/DetailDestinationGroup.vue'),
    }, {
        path: '/:workspace/buyers',
        name: ROUTES.manageBuyers,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageBuyers },
        component: () => import('@/views/call-tracking/buyers/ManageBuyers.vue'),
    }, {
        path: '/:workspace/buyers/:slug',
        name: ROUTES.detailBuyer,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageBuyers },
        component: () => import('@/views/call-tracking/buyers/DetailBuyer.vue'),
    }, {
        path: '/:workspace/vendors',
        name: ROUTES.manageVendors,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageVendors },
        component: () => import('@/views/call-tracking/vendors/ManageVendors.vue'),
    }, {
        path: '/:workspace/vendors/:slug',
        name: ROUTES.detailVendor,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageVendors },
        component: () => import('@/views/call-tracking/vendors/DetailVendor.vue'),
    }, {
        path: '/:workspace/call-flows',
        name: ROUTES.callFlows,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.callFlows },
        component: () => import('@/views/call-tracking/call-flows/CallFlows.vue'),
    }, {
        path: '/:workspace/call-flows/:slug',
        name: ROUTES.detailCallFlows,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.callFlows },
        component: () => import('@/views/call-tracking/call-flows/DetailCallFlows.vue'),
    }, {
        path: '/:workspace/traffic-sources',
        name: ROUTES.manageTrafficSources,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageTrafficSources },
        component: () => import('@/views/call-tracking/traffic-sources/ManageTrafficSources.vue'),
    }, {
        path: '/:account/white-label',
        name: ROUTES.whiteLabel,
        meta: { layout: 'main', middleware: [auth, isOwner, checkWorkspace, trialEnded], reference: ROUTES.whiteLabel },
        component: () => import('@/views/settings/account-setting/white-label/WhiteLabel.vue'),
    }, {
        path: '/:workspace/getting-start',
        name: ROUTES.gettingStart,
        meta: {
            layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.gettingStart,
        },
        component: () => import('@/views/getting-start/GettingStart.vue'),
    }, {
        path: '/:workspace/traffic-sources/:slug',
        name: ROUTES.detailTrafficSource,
        meta: { layout: 'main', middleware: [auth, isManager, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageTrafficSources },
        component: () => import('@/views/call-tracking/traffic-sources/DetailTrafficSource.vue'),
    }, {
        path: '/:account/workspaces/:workspace',
        name: ROUTES.manageWorkspace,
        meta: {
            layout: 'main', middleware: [auth, isOwner, isWorkspaceOwner, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageWorkspace,
        },
        component: () => import('@/views/workspaces/ManageWorkspaces.vue'),
    }, {
        path: '/:account/account-setting',
        name: ROUTES.accountSetting,
        meta: {
            layout: 'main', middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.accountSetting,
        },
        component: () => import('@/views/settings/account-setting/account/Account.vue'),
    }, {
        path: '/profile',
        name: ROUTES.userSetting,
        meta: {
            layout: 'main', middleware: [auth, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.userSetting,
        },
        component: () => import('@/views/settings/profile/Profile.vue'),
    }, {
        path: '/password',
        name: ROUTES.password,
        meta: {
            layout: 'main', middleware: [auth, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.password,
        },
        component: () => import('@/views/settings/security/password/Password.vue'),
    }, {
        path: '/:account/recharge-balance',
        name: ROUTES.rechargeBalance,
        meta: {
            layout: 'main', middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.rechargeBalance,
        },
        component: () => import('@/views/settings/billing/recharge-balance/RechargeBalance.vue'),
    }, {
        path: '/:account/billing-profile',
        name: ROUTES.billingProfile,
        meta: {
            layout: 'main', middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.billingProfile,
        },
        component: () => import('@/views/settings/billing/billing-profile/BillingProfile.vue'),
    }, {
        path: '/two-factor-auth',
        name: ROUTES.twoFactorAuth,
        meta: {
            layout: 'main', middleware: [auth, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.twoFactorAuth,
        },
        component: () => import('@/views/settings/security/two-factor-auth/TwoFactorAuth.vue'),
    }, {
        path: '/login-security',
        name: ROUTES.loginSecurity,
        meta: {
            layout: 'main', middleware: [auth, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.loginSecurity,
        },
        component: () => import('@/views/settings/security/login-security/LoginSecurity.vue'),
    }, {
        path: '/:account/payment-history',
        name: ROUTES.paymentHistory,
        meta: {
            layout: 'main', middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.paymentHistory,
        },
        component: () => import('@/views/settings/billing/payment-history/PaymentHistory.vue'),
    }, {
        path: '/:account/api-access-tokens',
        name: ROUTES.apiAccessTokens,
        meta: {
            layout: 'main', middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.apiAccessTokens,
        },
        component: () => import('@/views/settings/security/api-access-token/ApiAccessTokens.vue'),
    }, {
        path: '/:account/protection',
        name: ROUTES.protection,
        meta: {
            layout: 'main', middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.protection,
        },
        component: () => import('@/views/settings/security/protection/Protection.vue'),
    }, {
        path: '/:workspace/verification',
        name: ROUTES.verification,
        meta: { layout: 'main', middleware: [auth, isManager, isNotReviewsTrialEnded], reference: ROUTES.verification },
        component: () => import('@/views/verification/VerificationPage.vue'),
    }, {
        path: '/:account/manage-components',
        name: ROUTES.manageCards,
        meta: {
            layout: 'main', middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageCards,
        },
        component: () => import('@/views/settings/billing/manage-cards/ManageCards.vue'),
    },
    {
        path: '/:workspace/workspace-activity',
        name: ROUTES.workspaceActivity,
        meta: {
            layout: 'main', middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.workspaceActivity,
        },
        component: () => import('@/views/settings/security/workspace-activity/ManageWorkspaceActivity.vue'),
    }, {
        path: '/:workspace/workspace-activity/:slug',
        name: ROUTES.detailWorkspaceActivity,
        meta: {
            layout: 'main', middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.workspaceActivity,
        },
        component: () => import('@/views/settings/security/workspace-activity/DetailWorkspaceActivity.vue'),
    },
    {
        path: '/not-found-workspace',
        name: ROUTES.notFoundWorkspace,
        meta: { layout: 'workspace', middleware: [auth, accountInReview, trialEnded, isNotEmptyWorkspace], reference: ROUTES.notFoundWorkspace },
        component: () => import('@/views/NotFoundWorkspace.vue'),
    }, {
        path: '/account-disabled',
        name: ROUTES.accountDisabled,
        meta: { layout: 'empty', middleware: [auth, accountInReview, trialEnded, checkWorkspace, isAccountDisabled], reference: ROUTES.accountDisabled },
        component: () => import('@/views/account-disabled/AccountDisabled.vue'),
    }, {
        path: '/:workspace/integrations',
        name: ROUTES.manageIntegrations,
        meta: { layout: 'main', middleware: [auth, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageIntegrations },
        component: () => import('@/views/call-tracking/integrations/manage-integrations/ManageIntegrations.vue'),
    }, {
        path: '/:workspace/integration/google-ads',
        name: ROUTES.googleAdsIntegration,
        meta: { layout: 'main', middleware: [auth, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageIntegrations },
        component: () => import('@/views/call-tracking/integrations/google-integration/GoogleIntegration.vue'),
    }, {
        path: '/:workspace/integration/google-ads/traffic-sources/:slug',
        name: ROUTES.googleAdsIntegrationTrafficSource,
        meta: { layout: 'main', middleware: [auth, checkWorkspace, accountInReview, trialEnded], reference: ROUTES.manageIntegrations },
        component: () => import('@/views/call-tracking/traffic-sources/DetailTrafficSource.vue'),
    }, {
        path: '/:pathMatch(.*)*',
        name: ROUTES.notFound,
        meta: { layout: 'empty', middleware: notFound, reference: ROUTES.notFound },
        component: () => import('@/views/NotFound.vue'),
    },
]
