import { defineStore } from 'pinia'

import { getNotifications, updateNotification } from '@/api/notifications-routes'

import { useDefaultStore } from '@/store/defaultStore'
import { useAuthorizationStore } from '@/store/authorizationStore'

export const useNotificationsStore = defineStore('notificationsStore', {
    state: () => ({
        loading: false,
        loadingRead: false,
        notifications: [],
        notificationInterval: null,
        loadingReadIds: {},
    }),

    getters: {
        showNotification() {
            const authorizationStore = useAuthorizationStore()

            return authorizationStore.isManager && !authorizationStore.isAccountReview
        },

        unreadNotifications(state) {
            return state.notifications.filter((notification) => !notification.read_at)
        },

        lastUnreadUrgentlyNotification() {
            return this.unreadNotifications.find((notification) => notification.data.type === 'recommendation')
        },

        readNotifications(state) {
            return state.notifications.filter((notification) => notification.read_at)
        },
    },

    actions: {
        setIntervalNotification() {
            clearInterval(this.notificationInterval)

            if (!this.showNotification) {
                return
            }

            this.loadNotifications()

            this.notificationInterval = setInterval(() => {
                this.loadNotifications()
            }, 60000)
        },

        async loadNotifications() {
            const defaultStore = useDefaultStore()
            this.loading = true

            const { success, payload, message } = await getNotifications()

            if (success) {
                this.notifications = payload
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.loading = false
        },

        async onRead(id, index) {
            const defaultStore = useDefaultStore()

            if (id && this.loadingReadIds[id]) {
                return
            }

            if (!id && this.loadingRead) {
                return
            }

            this.setLoadingReadId({ id })

            const { success, message } = await updateNotification(id ? { id } : null)

            if (success) {
                this.notificationsAfterRead(id, index)
                defaultStore.setSuccessMessage({ message })
            } else {
                defaultStore.setErrorMessage({ message })
            }

            this.setLoadingReadId({ id, loading: false })
        },

        setLoadingReadId({ id, loading = true }) {
            if (id) {
                this.loadingReadIds[id] = loading
            } else {
                this.loadingRead = loading
            }
        },

        notificationsAfterRead(id) {
            if (id) {
                const index = this.notifications.findIndex((item) => item.id === id)

                if (index + 1) {
                    this.notifications.splice(index, 1, { ...this.notifications[index], read_at: new Date() })
                }
            } else {
                this.markAsReadAllNotifications()
            }
        },
        markAsReadAllNotifications() {
            this.notifications.forEach((notification) => {
                notification.read_at = new Date()
            })
        },
    },
})
