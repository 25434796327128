<template>
    <div class="flex h-full w-full">
        <AppBar
            :mini="mini"
            :marginClass="marginClass"
            @showSideBars="showSideBars"
        />

        <SideBar
            v-model:mini="mini"
            v-model:showMobileSideBars="showMobileSideBars"
        />

        <div
            class="bg-white dark:bg-blue-800 w-full overflow-hidden transition-spacing"
            :class="marginClass"
            @click="hideSideBars"
        >
            <div
                class="relative w-full transition-all"
                :class="mainClass"
            >
                <router-view v-slot="{ Component, route }">
                    <transition name="fade">
                        <div class="h-full" :key="route.name">
                            <component :is="Component" />
                        </div>
                    </transition>
                </router-view>

                <transition name="fade">
                    <div
                        v-if="routeIsLoading"
                        class="absolute top-0 w-full h-full flex items-center justify-center z-120 bg-black/4"
                    >
                        <MainLoader />
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    ref, computed, provide, onMounted, watch, inject, onBeforeMount,
} from 'vue'
import { useRoute } from 'vue-router'

import SideBar from '@/components/navigation/SideBar/SideBar.vue'
import AppBar from '@/components/navigation/AppBar.vue'
import MainLoader from '@/components/mainLoader/MainLoader.vue'

import { getBarsPositions, setBarsPositions, addEventListenerStorage } from '@/helpers/app-local-storage-helper'
import {
    addFeaturebaseScript, initFeaturebase, initFeaturebaseSurvey, initFeaturebaseChangelog,
} from '@/helpers/featurebase-helper'

import { viewPorts } from '@/constants/viewPorts'
import { useAuthorizationStore } from '@/store/authorizationStore'
import { useBreadCrumbsStore } from '@/store/breadCrumbsStore'
import { useAppStore } from '@/store/appStore'

const route = useRoute()

const breadCrumbsStore = useBreadCrumbsStore()
const appStore = useAppStore()
const authorizationStore = useAuthorizationStore()

const currentViewSize = inject('currentViewSize')

const mini = ref(false)

const showMobileSideBars = ref(false)

provide('mini', mini)
provide('showMobileSideBars', showMobileSideBars)

const isMbView = computed(() => viewPorts.mb >= currentViewSize.value)

const isAccountTrial = computed(() => authorizationStore.isAccountTrial)

const marginClass = computed(() => [
    mini.value && 'min-mb:ml-from-side-bars-min',
    !mini.value && 'min-mb:ml-from-side-bars-max',
])

const mainClass = computed(() => {
    if (isAccountTrial.value) {
        return breadCrumbsStore.showMobileBC && isMbView
            ? 'mt-from-app-bar-with-bc-trial h-main-container-with-bc-trial'
            : 'mt-from-app-bar-with-trial h-main-container-with-trial'
    }

    return breadCrumbsStore.showMobileBC && isMbView
        ? 'mt-from-app-bar-with-bc h-main-container-with-bc'
        : 'mt-from-app-bar h-main-container'
})

const routeIsLoading = computed(() => appStore.routeIsLoading)

onBeforeMount(() => {
    addFeaturebaseScript()
})

onMounted(() => {
    getSideBarsPositions()
    addEventListenerStorage()

    initFeaturebase()
    initFeaturebaseSurvey()
    initFeaturebaseChangelog()
})

function getSideBarsPositions() {
    const positions = getBarsPositions()

    mini.value = positions.mini
}

function showSideBars() {
    mini.value = false

    showMobileSideBars.value = true
}

function hideSideBars() {
    showMobileSideBars.value = false
}

watch(() => ({ mini: mini.value }), (positions) => {
    setBarsPositions(positions)
})

watch(route, () => {
    hideSideBars()
})
</script>
