import axios from '@/plugins/axios'
import { useAuthorizationStore } from '@/store/authorizationStore'

const getAccountId = () => useAuthorizationStore().getAccountSlug

export const checkWhiteLabel = () => axios.get('whitelabel/check')
export const getWhiteLabel = () => axios.get(`${getAccountId()}/white-label`)
export const updateWhiteLabel = (formData) => axios.patch(`${getAccountId()}/white-label`, formData)
export const deleteWhiteLabel = () => axios.delete(`${getAccountId()}/white-label`)
export const issueCertificateWhiteLabel = () => axios.post(`${getAccountId()}/white-label/certificate`)
export const verifyCertificateIssued = () => axios.get(`${getAccountId()}/white-label/certificate/is-issued`)
export const verifyCertificateSet = () => axios.get(`${getAccountId()}/white-label/certificate/is-set`)
export const createDistribution = () => axios.post(`${getAccountId()}/white-label/distribution`)
export const verifyDistributionSet = () => axios.get(`${getAccountId()}/white-label/distribution/is-set`)
export const getStatusWhiteLabel = () => axios.get(`${getAccountId()}/white-label/status`)
