function loginCarrotquest({ userId, hash }) {
    if (!window.carrotquest) {
        return
    }

    if (hash && userId) {
        carrotquest.auth(userId, hash)
    }
}

function setUserDataToCarrotquest(userData) {
    if (!window.carrotquest) {
        return
    }

    carrotquest.identify(userData)
}

function setTrackToCarrotquest(track) {
    if (!window.carrotquest) {
        return
    }

    carrotquest.track(track)
}

export {
    loginCarrotquest,
    setUserDataToCarrotquest,
    setTrackToCarrotquest,
}
